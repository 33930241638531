import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://d7f0b2ce78a19c8b0f7fb5aa53a1586f@o4507727340044288.ingest.us.sentry.io/4507727468691456",
  debug: false,
  environment: "production",
  release: "ab34f4b7535103c0b75f65826d577e3b2002587e",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()